@import '@foundations/media-queries.scss';
@import '@foundations/text-styles.scss';

$heightMenu: 84px;
$extraHeightMenu: var(--heightMenu);

.containerDesktop {
  display: none;
  @media (min-width: $large-viewport) {
    display: block;
  }

  background-color: var(--ui01);
  position: sticky;
  top: 35px;
  z-index: 200;
  width: 100%;

  height: $heightMenu;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);

  &.top {
    top: 0;
    transition: all 0.4s ease;
  }

  .headerContainer {
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 12%;
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 88%;

      .menuHorizontal {
        flex: 0 0 76%;

        [data-level='1'] {
          > .btnLink {
            position: relative;
            color: white;
            text-decoration: none;

            &:before {
              content: '';
              position: absolute;
              top: 42%;
              left: -16px;

              transform: translate(0, -50%);

              width: 1px;
              height: 36px;
              background-color: var(--ui03);
              opacity: 0.12;
            }
          }

          &:first-child {
            > .btnLink {
              &:before {
                content: '';
                position: relative;
              }
            }
          }
        }

        .listItem {
          padding: 0 var(--spc-xxs);
          display: flex;
          align-items: center;

          > div {
            padding: 0;
            border-bottom: 0;

            > span,
            > a {
              @extend .s-caption-button;
              display: flex;
              align-items: center;

              > svg {
                padding-left: 0;
                margin-left: var(--spc-xxxs);
              }
            }
          }

          &:hover {
            > .btnLink {
              border-bottom: 1px solid var(--textAccent);

              span,
              a {
                color: var(--textAccent) !important;
              }

              > svg {
                display: none;
              }
            }
          }

          &.isLink {
            svg {
              display: none;
            }
          }

          &.isGroup {
            > .btnLink {
              > a,
              > span {
                font-size: 12px;
                line-height: 16px;
                flex: 0 0 100%;
                justify-content: space-between !important;

                > svg {
                  height: 24px;
                  width: 7px;
                  padding-left: 0;
                }
              }
            }
          }
        }

        [data-level='menu-0'] {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 var(--spc-xxxs);
          margin: 0;
          height: 100%;
          width: 100%;

          > [data-is-group='isGroup'] {
            > .btnLink {
              > a,
              > span {
                cursor: pointer;
                flex: 0 0 100%;
                justify-content: space-between !important;
                color: white!important;
                > svg {
                  height: 24px;
                  width: 7px;
                  padding-left: 0;
                  transform: rotate(90deg);
                }
              }
            }

            &:hover {
              > .btnLink {
                border-bottom: 1px solid var(--textAccent);

                span,
                a {
                  color: var(--textAccent) !important;
                }

                svg {
                  margin-left: var(--spc-xxxs);
                  padding-left: 0;
                  transform: rotate(270deg);

                  path {
                    stroke: var(--textAccent);
                  }
                }
              }
            }
          }

          > [data-level='1'] {
            color: white;
            padding: 0 var(--spc-xxs);
            height: 84px;
            display: flex;
            align-items: center;
          }
        }

        .submenu {
          display: none;
          width: 100%;
          max-width: 100%;
          min-height: 420px;
          height: auto;
          overflow: hidden;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15),
            0 3px 6px rgba(0, 0, 0, 0.1);
        }

        [data-is-group='isGroup'] {
          &:hover {
            > .submenu {
              display: block;
              position: absolute;
              //top: var(--heightMenu);
              top: 84px;
              left: 0;
              visibility: visible;
              z-index: 4;
              opacity: 1;
              transition: opacity 0.5s ease;
              background: white;

              .lineLarge {
                position: absolute;
                top: 0;
                right: 0;
                width: 50vw;
              }

              [data-level='menu-2'] {
                position: relative;
                [data-level='2'] {
                  &:hover {
                    [data-level='menu-3'] {
                      display: flex;
                    }
                  }
                }
              }

              [data-level='menu-3'] {
                display: none;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: row;
                position: absolute;
                top: 0;
                background-color: white;
                z-index: 5;

                @media (min-width: $medium-viewport) {
                  width: calc(840px - 0.25 * 840px);
                  left: calc(0.25 * 840px);
                }

                @media (min-width: $large-viewport) {
                  width: calc(1128px - 0.25 * 1128px);
                  left: calc(0.25 * 1128px);
                }

                &:after {
                  content: '';
                  position: absolute;
                  top: -50px;
                  width: 100vw;
                  height: 400px;
                  background-color: white;
                  z-index: 1;
                }

                [data-level='3'] {
                  display: block;
                  height: fit-content;
                  position: relative;
                  padding-left: var(--spc-s);
                  z-index: 2;

                  .btnLink {
                    padding-bottom: var(--spc-s);
                  }

                  &:after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: var(--ui03);
                    position: absolute;
                    top: 0;
                    left: 15px;
                  }

                  [data-level='menu-4'] {
                    display: block;
                  }
                }
              }

              [data-level='menu-4'] {
                width: 100%;
                flex-direction: column;
                position: relative;

                [data-level='4'] {
                  width: 100%;
                  max-width: 100%;
                  padding-right: 0;
                  padding-left: var(--spc-xxs);
                  position: relative;

                  > .btnLink {
                    padding-bottom: var(--spc-xxs);

                    span,
                    a {
                      @extend .body-inline;
                      text-transform: inherit;
                    }
                  }

                  &:after {
                    content: '';
                    height: 4px;
                    width: 4px;
                    background-color: #001c47;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    left: 0;
                  }
                }
              }

              [data-level='3'],
              [data-level='4'] {
                > .btnLink:first-child {
                  span,
                  a {
                    padding-bottom: 0;

                    > svg {
                      display: none;
                    }
                  }
                }

                > .btnLink {
                  padding: 0;
                  border: none;
                }
              }
            }
          }
        }
      }

      .childMenu {
        padding: var(--spc-l) 0;
      }

      .menuVertical {
        width: calc(3 * 100% / 12);
        //background-color: yellow;
        .list {
          width: 100%;
          padding-bottom: var(--spc-xxs);

          .listItem {
            width: 100%;

            &:first-child {
              div {
                padding-top: 0;
              }
            }

            > .btnLink {
              width: 100%;
              border-bottom: 1px solid var(--ui06);
              padding: var(--spc-s) 0 var(--spc-xxs) 0;

              span,
              a {
                color: var(--ui06) !important;
              }

              svg {
                path {
                  stroke: var(--ui06) !important;
                }
              }
            }

            &:hover {
              > .btnLink {
                border-bottom: 1px solid var(--textAccent);

                span,
                a {
                  color: var(--textAccent) !important;
                }

                svg {
                  path {
                    stroke: var(--textAccent) !important;
                  }
                }
              }
            }
          }
        }
      }

      .menuCallToAction {
        flex: 0 0 auto;
      }
    }
  }
}
.image {
  width: 550px;
  height: 280px;
  position: relative;
}
