@import '@foundations/media-queries.scss';
@import '@foundations/text-styles.scss';

.container {
  background-color: var(--ui01);
  position: sticky;
  top: 0;
  z-index: var(--z-index-super-header);

  &.hidden {
    display: none;
  }

  @media (max-width: $large-viewport) {
    display: none;
  }

  padding: var(--spc-xxxs) 0;

  > * {
    color: var(--textRegular);
    @extend .xs-caption-button;
  }

  svg {
    path {
      stroke: white;
    }
  }

  > div {
    align-items: center;

    div {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .iconPhone,
    .search {
      cursor: pointer;
      padding-right: var(--spc-s);
    }

    .search {
      span {
        padding-left: var(--spc-xxxs);
      }
    }


    .iconPhone {
      a {
        color: white!important;
        text-decoration: none;
        padding-left: var(--spc-xxxs);

      }
      svg{
        width: 16px;
        height: 16px;
      }
    }

  }
}

