@import '@foundations/media-queries.scss';
@import '@foundations/text-styles.scss';

$heightMenu: 84px;
$extraHeightMenu: var(--heightMenu);

.containerMobile {
  display: none;

  @media (max-width: $large-viewport) {
    display: block !important;
  }

  background-color: var(--ui01);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 200;

  height: $heightMenu;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);

  .headerContainer {
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spc-xxs) 0;

    .logo {
      min-width: 129px;
      height: 54px;
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 88%;

      > :first-child {
        margin-right: var(--spc-xs);
      }

      svg {
        cursor: pointer;

        path {
          stroke: white;
        }
      }
    }
  }

  .containerMenus {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 84px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
    display: none;
    overflow-x: scroll;

    @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
      min-height: 380px;
      height: auto;
    }

    &[data-theme='blue'] {
      background-color: var(--ui01);
    }

    &[data-theme='light'] {
      background-color: white;
    }

    &.active {
      display: block;
    }

    .listItemHeader {
      background-color: var(--area-bg04);
      width: 100%;
      left: -500%;
      position: absolute;

      &.active {
        position: relative;
        left: 0;
      }

      .list {
        padding: var(--spc-xxs) 0;

        .listItem {
          display: flex;
          &::before {
            content: '';
            display: inline-block;
            background-color: var(--textUltraAccent);
            flex: 20px;
            height: 1px;
            margin: 9px 0;
          }

          .btnLink {
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%;
            border: 0;

            span,
            a {
              color: var(--ui06) !important;
              padding: 0;
              justify-content: flex-end !important;
              flex-direction: row-reverse;
              flex: 0 0 100%;
              margin-left: var(--spc-xxs);
              svg {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .menuVertical {
    position: absolute;
    //left: -500%;
    //transition: all 0.3s ease;
    opacity: 0;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
      flex-direction: row;
    }

    padding: var(--spc-l) 0;

    [data-level] {
      margin: 0;
    }

    .menu,
    .right {
      @media (max-width: $medium-viewport) {
        width: 100%;
      }

      @media (min-width: $small-viewport) {
        width: calc(10 * 100% / 12);
      }
    }

    .menu {
      @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
        width: calc(4 * 100% / 12);
      }

      @media (max-width: $medium-viewport) {
        padding-bottom: var(--spc-l);
      }

      .list {
        padding: 0;
      }

      .listItem {
        position: relative;
        padding-bottom: var(--spc-s);
        //@media (min-width: $medium-viewport) and (max-width: $large-viewport) {
        //  padding-bottom: var(--spc-xs);
        //}

        display: flex;
        align-items: center;

        > .btnLink {
          padding: 0;
          width: 100%;

          > span,
          > a {
            @extend .s-caption-button;

            > svg {
              padding-left: 0;
              margin-left: var(--spc-xxxs);
            }
          }
        }

        &:hover {
          > .btnLink {
            border-bottom: 1px solid var(--textAccent);

            span,
            a {
              color: var(--textAccent) !important;
            }

            > svg {
              display: none;
            }
          }
        }

        &.isLink {
          svg {
            display: none;
          }
        }

        .btnLink {
          > span,
          > a {
            flex: 0 0 100%;
            justify-content: space-between !important;
            align-items: flex-start !important;
            min-height: 40px;
            > svg {
              display: none;
            }
          }
        }

        &.isGroup {
          &:hover {
            .btnLink {
              svg {
                path {
                  stroke: var(--textAccent) !important;
                }
              }
            }
          }
        }
      }
    }

    &.active {
      position: relative;
      //left: 0;
      opacity: 1;

      &.submenu {
        width: 100%;

        @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
        }

        @media (max-width: $medium-viewport) {
          display: block;
        }

        padding: var(--spc-s) 0;

        .list {
          .listItem {
            display: block;
          }
        }

        .plusButton {
          background-color: var(--ui03);
          &:hover {
            background-color: var(--ui02);
          }
        }

        [data-level='menu-3'] {
          display: block;
          width: 100%;

          .list {
            width: 100%;
            @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
              display: flex;
              align-items: flex-start;
            }

            @media (max-width: $medium-viewport) {
              display: block;
            }
          }

          [data-level='3'] {
            @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
              width: calc(4 * 100% / 12);
              position: relative;
              padding: 0 var(--spc-xs);

              display: flex;
              flex-direction: column;

              &:after {
                content: '';
                width: 1px;
                height: 100%;
                background-color: var(--ui03);
                position: absolute;
                top: 0;
                left: 0;
              }

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }

              &:first-child {
                &:after {
                  content: '';
                  position: inherit;
                }
              }
            }

            .btnLink {
              > span,
              > a {
                min-height: 0;
              }
            }

            @media (max-width: $medium-viewport) {
              display: block;
              position: relative;
              padding-top: var(--spc-xs);
              padding-bottom: calc(var(--spc-xs) - var(--spc-xxs));

              &:first-child {
                padding-top: 0;
              }

              &:after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: var(--ui03);
                position: absolute;
                bottom: 0;
                left: 0;
              }

              &:last-child {
                &:after {
                  content: '';
                  position: inherit;
                }
              }
            }
          }
        }

        [data-level='menu-4'] {
          width: 100%;
          flex-direction: column;
          position: relative;

          @media (min-width: $small-viewport) {
            columns: 2;
          }

          [data-level='4'] {
            width: 100%;
            max-width: 100%;
            padding-right: 0;
            padding-left: var(--spc-xxs);
            padding-bottom: 0;
            margin-bottom: var(--spc-xxs);
            position: relative;

            .btnLink {
              padding: 0;

              span,
              a {
                @extend .body-inline;
                text-transform: none;
              }
            }

            &:after {
              content: '';
              height: 4px;
              width: 4px;
              background-color: #001c47;
              border-radius: 50%;
              position: absolute;
              top: 6px;
              left: 0;
            }
          }
        }

        [data-level='3'],
        [data-level='4'] {
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: space-between;

          > .btnLink:first-child {
            span,
            a {
              padding-bottom: 0;

              > svg {
                display: none;
              }
            }
          }

          > .btnLink {
            padding: 0;
            border: none;
            padding-bottom: var(--spc-s);
          }
        }

        .right {
          .lineLarge {
            position: fixed;

            @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
              top: 138px;
              right: 0;
              width: 50vw;
            }

            @media (max-width: $medium-viewport) {
              left: 0;
              bottom: 10%;
              width: 100vw;
            }
          }
        }
      }
    }

    .right {
      @media (min-width: $medium-viewport) and (max-width: $large-viewport) {
        width: calc(5 * 100% / 12);
      }

      .menuRow {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;

        min-height: 100%;
      }
    }
  }
}

.rowLinks {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spc-s);
  width: 100%;

  @media (min-width: $small-viewport) {
    justify-content: flex-start;
    flex-direction: column;
  }
}

.socialIcons {
  width: 100%;
  padding-bottom: 0;

  > div {
    flex: 0 0 auto;
    padding-right: var(--spc-xs);

    @media (min-width: $medium-viewport) {
      padding-bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      a {
        display: flex;
      }
    }
    @media (min-width: $medium-viewport) {
      padding-bottom: 0;
    }
  }
}
.image {
  width: 300px;
  height: fit-content;
  position: relative;
  @media (min-width: $small-viewport) {
    width: 540px;
  }
}
.plusButton {
  background-color: #011737;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: -12px;
  border: 0;
  z-index: 999;
  transition: 0.2s ease;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: #fff;
  }
  &::before {
    top: calc(50% - 0.5px);
    left: 28%;
    width: 44%;
    height: 1px;
  }
  &::after {
    left: calc(50% - 0.5px);
    top: 28%;
    height: 44%;
    width: 1px;
  }

  &:hover {
    background-color: #02214f;
  }
}

.close {
  display: none;
}
